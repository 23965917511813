.backdrop {
  width: 100%;
  background-color: rgb(255, 255, 255, 0.8);
  font-weight: 600;
  height: 100%;
  padding-bottom: 70px;
}

.content {
  z-index: 3;
  position: relative;
  background: url("../assets/imgs/EF Branding Website/warehouse.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-bottom: 0px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left top;
}
.divider {
  width: 500px;
  margin-top: 15px;
  border-bottom: 2px solid black;
}

@media screen and (max-width: 825px) and (min-width: 414px) {
  .divider {
    width: 320px;
  }
}
@media screen and (max-width: 414px) {
  .divider {
    width: 300px;
  }
}
