
.btn{
    width: 100%;
    margin-left: 20px;
    background-color: #DDDDDD;
    border: none;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.icon{
  font-size: 1.8rem;
  color: black;
}
.img{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}
@media screen and (max-width: 393px) {
  .icon{
    display: inline-block;
  }
 .icon_container{
    width: 70px;
    display: flex;
    justify-content: end;
 }
}