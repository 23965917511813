.content {
  width: 100%;
  height: 55vh;
  /* background: url("../assets/imgs/background_img_new.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: animate 20s infinite;
}
@keyframes animate {
  0%,
  100% {
    background-image: url(../assets/imgs/background_img_new.jpg);
  }
  25% {
    background-image: url(../assets/imgs/tracking_banner.jpg);
  }
  50% {
    background-image: url(../assets/imgs/info.jpg);
  }
  75% {
    background-image: url(../assets/imgs/background_img.jpg);
  }
}

.backdrop {
  padding-left: 10%;
  padding-top: 200px;
  width: 100%;
  height: 55vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}
.tb_container {
  display: flex;
  margin-top: 20px;
  width: 72%;
}

.tb_container input {
  background: #f3f5f6;
  border: none;
  border-radius: 0%;
}

.tb_container button {
  background: #1a2753;
  border: none;
  height: 50px;
  width: 12rem;
  border-radius: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.tb_container button:hover {
  background: #1a2753;
}
.modal_box, .thz_modal_box{
  top: 15%;
  border-radius: 30px;
}

@media only screen and (max-width: 950px) {
  .content {
    height: 600px;
  }

  .backdrop {
    height: 600px;
  }
}
.tb_container button {
  font-size: 12px;
}

.backdrop p {
  font-size: 16px;
}

.backdrop h1 {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0;
}

.service_article {
  max-width: 900px;
}
@media screen and (max-width: 825px) and (min-width: 414px) {
  .content,
  .backdrop {
    height: 35vh;
  }
  .backdrop {
    padding-top: 50px;
  }
  .backdrop h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 414px) {
  .content,
  .backdrop {
    height: 30vh;
  }
  .backdrop {
    padding-top: 30px;
  }
  .backdrop h1 {
    font-size: 25px;
    width: 300px;
    line-height: 50px;
    letter-spacing: 0;
  }
  .tb_container {
    width: 75%;
  }
  .tb_container button {
    font-size: 12px;
    height: 40px;
    width: 15rem;
  }
  .tb_container input::placeholder {
    font-size: 12px;
  }
  .modal_box {
  top: 5%;
  border-radius: 30px;
  left: 0%;
  right: 0%;
}
.thz_modal_box{
  top: 30%;
}
}
