.why-choose-us-section {
  background-color: white;
  
}

.why-choose-us-header {
  font-size: 35px;
  width: 100%;
  padding-left: 40px;
  margin-bottom: 30px;
}
.why-choose-us-content {
  width: 70%;
}
.why-choose-us-content h5 {
  font-size: 25px;
  font-weight: bold;
}
.why-choose-us-content p {
  color: rgb(110, 109, 109);
  font-size: 20px;
}
.check-icon {
  color: yellowgreen;
  font-size: 30px;
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
}

  .solution-section {
    padding:60px 50px;

  }

  .solution-section .header {
    text-align: center;
  }

  .solution-section .header .box-icon {
    font-size: 22px;
    color:#1A2753;
  }

  .solution-section .header p {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 15px;
    padding:0px 20px;
  }

  .solution-section .info-container {
    margin-top: 50px;
    display: flex;
  }

  .solution-section .info-container .info {
    display: flex;
    align-items: flex-start;
    flex:1;
    padding:0px 20px;
  }

  .solution-section .info-container .info img {
    width:35px;
    height:50px;
  }

  .solution-section .info-container .info div {
    padding-left: 20px;
  }

  .solution-section .info-container .info h6 {
    font-size: 18px;
    text-transform: uppercase;
  }

  .solution-section .info-container .info p {
    font-size: 15px;
    text-align: left;
    color: rgb(110, 109, 109);
  }
  .next-step-section 
  {
    text-align: center;
    padding:4px 80px 100px 80px;
    
  }
  .next-step-section .header {
    text-align: center;
  }

  .next-step-section  p {
    margin:20px 0px;
  }

  .next-step-section .btn {
    background: #1A2753;
    font-size: 15px;
  }
    .next-step-section .btn:hover{
      background: #1A2753;
    }

  .next-step-section .header h6 {
    font-size: 18px;
    text-transform: uppercase;
  }


  @media only screen and (max-width: 1250px) {
    .why-choose-us-content {
      width: 80%;
    }
    .why-choose-us-content p{
      font-size: 16px;
    }
    .check-icon {
    margin-left: 10px;
  }
  }


  @media only screen and (max-width: 950px) {
    .solution-section .info-container {
      display: flex;
      flex-direction: column;
    }
    .solution-section .info-container .info {
      margin-bottom: 30px;;
    }
    .check-icon{
      margin-left: 50px;
    }
    .why-choose-us-content h5{
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 650px) {
  .check-icon{
    font-size: 25px;
    display: block;
    margin-left: 20px;
  }
  }