* {
  padding: 0;
  margin: 0;
}

/* Custom Tabs Styes */
.nav .nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link.active {
  color: #2B5188 !important;
  background-color: #fff !important;
  border: none !important;
  border-top: 3px solid #2B5188 !important;
}

.nav .nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link {
  color: #fff !important;
  background-color: #2B5188 !important;
}

.carousel-control-next-icon,.carousel-control-prev-icon {
  background-image: none !important;
}