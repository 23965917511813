.content {
  width: 100%;
  height: 45vh;
  /* background: url("../assets/imgs/background_img.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: animate 20s infinite;
}
@keyframes animate {
  0%,
  100% {
    background-image: url(../assets/imgs/background_img.jpg);
  }
  25% {
    background-image: url(../assets/imgs/tracking_banner.jpg);
  }
  50% {
    background-image: url(../assets/imgs/info.jpg);
  }
  75% {
    background-image: url(../assets/imgs/background_img_new.jpg);
  }
}

.backdrop {
  width: 100%;
  height: 45vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}
.about_us_container {
  margin-top: 18px;
}
.banner_image {
  background: url("../assets/imgs/background_img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.office_details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.office_details h2 {
  color: rgb(110, 109, 109);
  font-weight: 500;
  padding: 20px 0px;
}
.office_details p,
.contact_details span {
  color: rgb(110, 109, 109);
  padding: 10px 0px;
}
.image {
  width: 100%;
  height: 100%;
}
.contact_details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.icon_design {
  color: #6ce1ee;
  font-size: 25px;
  margin-right: 10px;
}
.phones {
  display: flex;
}
.phones p {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.phones p span {
  padding: 0%;
}
.email {
  display: flex;
  flex-direction: column;
}
.email span {
  padding: 0%;
}
.icon_circle_design {
  border: 0.5px solid rgb(190, 188, 188);
  color: rgb(125, 124, 124);
  width: 30px;
  padding: 6px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}
.about_us p {
  margin-top: 20px;
}
.about_us_img {
  position: absolute;
  top: 90%;
  right: 15%;
}
.absolute_img {
  width: 400px;
  height: 200px;
}
.about_img_second {
  width: 350px;
  height: 200px;
}
.background {
  bottom: 30%;
  right: 30%;
  position: absolute;
  width: 350px;
  height: 180px;
  background-color: #1a2753;
  z-index: -1;
}
.background_right {
  bottom: 30%;
  left: 30%;
  position: absolute;
  width: 350px;
  height: 180px;
  background-color: #1a2753;
  z-index: -1;
}
.vision {
  padding: 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a2753;
  color: white;
  text-align: center;
}
.vision p {
  width: 780px;
}
.values {
  width: 150px;
  height: 180px;
  background-color: #1a2753;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.values_background {
  bottom: 30%;
  left: 30%;
  position: absolute;
  width: 150px;
  height: 180px;
  background-color: #7e849b;
  z-index: -1;
}
.sub-one {
  padding-right: 12%;
}
.padding_one,
.padding_two,
.padding_three {
  padding-left: 12%;
  font-weight: bold;
  text-align: center;
}
.padding_two {
  padding-left: 8%;
}
.padding_three {
  padding-left: 10%;
}
@media only screen and (max-width: 1250px) {
  .office_details {
    flex-direction: column;
  }
  .contact_details span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1250px) {
  .contact_details {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
}

@media screen and (max-width: 825px) and (min-width: 415px) {
  .about_us_img {
    position: absolute;
    top: 90%;
    right: 10%;
  }
  .absolute_img {
    width: 280px;
    height: 180px;
  }
  .values {
    width: 100px;
    height: 150px;
    font-size: 14px;
  }
  .values_background {
    width: 100px;
    height: 150px;
  }
}
@media screen and (max-width: 415px) {
  .about_us_container {
    margin-top: 0px;
  }
  .about_us {
    font-size: 15px;
  }
  .about_us p {
    margin-top: 20px;
  }
  .absolute_img {
    width: 250px;
    height: 150px;
  }
  .about_img_second {
    width: 250px;
    height: 150px;
  }
  .background,
  .background_right {
    display: none;
  }
  .padding_one,
  .padding_two,
  .padding_three {
    padding-left: 0%;
    padding-top: 20px;
  }
  .values {
    width: 120px;
    height: 150px;
    font-size: 14px;
    margin-right: 28px;
  }
  .values_background {
    width: 120px;
    height: 150px;
    left: 15%;
  }
}
