.side-banner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0px;
  box-shadow: 0px 10px 50px 0px rgb(17 17 17 / 4%);
}

.call-support,
.location {
  width: 400px;
  margin-right: 40px;
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.support-icon,
.location-icon {
  font-size: 60px;
  margin-right: 20px;
  color: #1a2753;
}

.call-support div h5,
.location div h5 {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
}

.call-support p,
.location p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(19, 28, 33, 0.6);
}

.side-banner .tb-container {
  display: flex;
}

.side-banner .tb-container .form-control {
  background: #f3f5f6;
  padding: 0 70px 0 20px !important;
  border: none;
  border-radius: 0;
}

.side-banner .tb-container .btn {
  background: #1a2753;
  border: none;
  height: 50px;
  width: 100px;
}
.text-white {
  font-weight: 700;
}
.text-white span {
  color: #223164;
}

.services {
  background: rgba(248, 249, 250);
  padding: 100px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.services .subtitle {
  width: 150px;
  padding: 4px 2px;
  font-weight: bold;
  text-transform: uppercase;
  color: #1a2753;
  text-align: center;
  font-size: 20px;
}

.services .title {
  margin-top: 8px;
  text-align: center;
  line-height: 1.2em;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
  font-size: 45px;
}

.services .image-cards {
  display: flex;
  margin-top: 36px;
}

.services .image-cards div {
  width: 400px;
  
  border-radius: 10px;
  background: white;
  padding: 30px 14px;
  position: relative;
  z-index: 1;
}
.services .image-cards div::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  background-color: #1a2753;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 500ms ease;
}
.services .image-cards div:hover::before {
  transform: scaleY(1);
}
.services .image-cards div:hover h3,
.services .image-cards div:hover p {
  color: white;
}

.services .image-cards div img {
  width: 100%;
  height: 250px;
}

.services .image-cards div h3 {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  margin: 16px 2px;
}
.services .image-cards div p {
  color: black;
}

.services .text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
}

.services .text-container p:first-child {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(19, 28, 33, 0.6);
  font-style: normal;
  margin-right: 22px;
}

.services .text-container p:nth-child(2) {
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.28px;
  font-family: "Poppins";
  font-weight: 400;
  color: #1a2753;
}
.about {
  padding: 120px 50px;
}
.about-title {
  padding-right: 100px;
}
.about-title span {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 20px;
}
.about-title h2 {
  line-height: 3.5rem;
  font-size: 2.8rem;
  font-weight: bolder;
  margin-bottom: 40px;
}
.about p {
  padding-right: 100px;
  color: rgb(82, 79, 79);
  line-height: 1.7rem;
  margin-bottom: 30px;
  font-size: 19px;
}
.about button {
  background-color: #1a2753;
  border: none;
  position: relative;
  z-index: 1;
  height: 3rem;
  margin-top: 20px;
}
.about button::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  border-radius: 15px;
  z-index: -1;
  background-color: #1a2753;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 1200ms ease-in-out;
}
.about button:hover {
  background-color: black;
}
.about button:hover:before {
  transform: scaleX(1);
  background-color: black;
}
.about-img {
  
  display: flex;
  justify-content: center;
  align-items: center;
}



/* .video-container {
  padding: 64px 104px;
  display: flex;
  background: rgba(248, 249, 250);
}

.video-container div:first-child {
  width: 40%;
  min-width: 700px;
}

.video-container div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
}

.video-container div:nth-child(2) h1 {
  font-size: 45px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.video-container div:nth-child(2) p {
  margin-bottom: 0;
  font-weight: inherit;
  color: inherit;
  margin-top: 0;
  line-height: inherit;
  width: 70%;
  margin-top: 18px;
}

.video-container div:first-child iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.video-container .deli-car {
  position: absolute;
  right: 340px;
  margin-top: 420px;
} */

.inquiry-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 150px;
  background: rgba(248, 249, 250);
  background-image: url("../assets/imgs/oean.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.inquiry-container div {
  flex: 1;
}

.inquiry-container div:first-child .form-card {
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;
  background: white;
  padding: 44px 20px;
  text-align: center;
  box-shadow: 1px 1px 10px rgb(176, 176, 176);
}

.inquiry-container div:first-child .form-card h1 {
  text-align: center;
  line-height: 1.2em;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
  font-size: 35px;
  margin-bottom: 22px;
}

.inquiry-container div:first-child .form-card .form-control {
  background: #f3f5f6;
  padding: 15px 20px;
  border: none;
  border-radius: 0;
  margin-bottom: 18px;
}

.inquiry-submit-btn {
  padding: 20px 35px !important;
  width: 80%;
  border: 1px solid #1a2753 !important;
  background: #1a2753 !important;
}

.inquiry-container div:nth-child(2) .inquiry-right-info {
  flex: 1;
  margin-top: 58px;
}
.inquiry-container .row {
  width: 100%;
}
.inquiry-container div:nth-child(2) .inquiry-right-info h6 {
  text-align: left;
  color: #131c21;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2em;
}

.inquiry-container div:nth-child(2) .inquiry-right-info h1 {
  padding: 20px 5px;
  width: 60%;
  font-size: 45px;
  line-height: 1.2;
  font-family: "Poppins";
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.inquiry-container div:nth-child(2) .inquiry-right-info p {
  font-size: 16px !important;
  text-align: left;
  color: rgba(19, 28, 33, 0.8);
  font-weight: normal;
  line-height: 1.56em;
  width: 60%;
}

.inquiry-container div:nth-child(2) .inquiry-right-info h4 {
  margin-top: 70px;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;

  font-style: normal;
}

.icon-list {
  padding-top: 10px;
}

.icon-list .icon {
  width: 50px;
  background: #1a2753;
  color: white;
  margin-right: 20px;
  line-height: 40px;
  text-align: center;
}

.inquiry-right-info .icon-list .desc {
  margin-top: -35px;
  margin-left: 80px;
}

.social-list {
  display: flex;
  margin-top: 100px;
}

.social-list span {
  margin-left: 10px;
}
.why-choose-us-section {
  background-color: white;
  padding: 50px 0px;
}

.why-choose-us-header {
  font-size: 39px;
  width: 100%;
  padding-left: 40px;
  margin-bottom: 30px;
}
.why-choose-us-content {
  width: 70%;
}
.why-choose-us-content h5 {
  font-size: 25px;
}
.why-choose-us-content p {
  color: rgb(110, 109, 109);
  font-size: 20px;
}
.check-icon {
  color: yellowgreen;
  font-size: 30px;
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
}
.blog-section-container {
  background: rgba(248, 249, 250);
  padding: 100px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.blog-section-container h3 {
  margin-bottom: 60px;
}
.blog-group {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-section {
  width: 350px;
  height: 350px;
  margin-right: 26px;
  border-radius: 10px;
  padding: 30px 14px;
  position: relative;
  z-index: 1;
}

.blog-section .card {
  width: 100%;
  height: 80%;
  overflow: hidden;
}
.card-img {
  transition: transform 300ms ease;
  width: 100%;
  height: 100%;
}
.card-img:hover {
  transform: scale(1.1);
}
.blog-review {
  display: flex;
  width: 100%;
  height: 20%;
  margin-top: 20px;
}
.blog-date {
  display: flex;
  flex-direction: column;
  height: 80%;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  background-color: #f3f5f6;
  border-radius: 5px;
}
.blog-date span:first-child {
  color: orangered;
  font-size: 30px;
  font-weight: bolder;
}
.blog-date span:last-child {
  text-transform: uppercase;
  font-size: 15px;
}
.blog-info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.blog-writerAndcomment {
  display: flex;
  width: 80%;
  justify-content: space-between;
  color: gray;
}
.blog-writerAndcomment span i {
  -webkit-text-stroke-color: gray;
  -webkit-text-stroke-width: 1px;
  color: white;
}
.blog-title {
  font-weight: bolder;
  text-align: left;
  font-size: 1.2rem;
}
.blog-title:hover {
  color: orangered;
  cursor: pointer;
}
.accomplish {
  background: rgba(248, 249, 250);
  padding: 40px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.accomplish .subtitle {
  background: #1a2753;
  width: 200px;
  padding: 8px 12px;
  border-radius: 22px 0px 22px 0px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.accomplish .title {
  margin-top: 8px;
  text-align: center;
  line-height: 1.2em;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
  font-size: 45px;
}

.numbers-container {
  display: flex;
  padding: 34px 254px;
}

.numbers-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.numbers-container div h1 {
  font-size: 45px;
  line-height: 1.2;
  margin-top: 16px;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.numbers-container div h2 {
  font-size: 25px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.numbers-container div p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: rgba(19, 28, 33, 0.6);
  font-style: normal;
  width: 80%;
}
.reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url(../assets/imgs/background_img.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding: 150px 0px;
}
.reviews::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  background-color: rgb(26, 39, 83, 0.9);
}
.review-container {
  display: flex;
  width: 550px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
}
.carousel {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}
.review-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-person {
  display: flex;
}
.review-person div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.carousel .img-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
}
.carousel .img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.carousel button {
  outline: none;
  margin-right: 10px;
  background-color: transparent;
  font-size: 10px;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.carousel button i {
  color: gray;
}
.carousel button:hover {
  border: 1px solid orangered;
}
.carousel button:hover i {
  color: orangered;
}
.carousel-item p {
  text-align: left;
  margin-bottom: 40px;
}

.card-container {
  width: 350px;
  padding: 20px;
  margin-left: 100px;
  border-radius: 15px;
}
.card-container .card-title {
  width: 100%;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 2rem;
  font-weight: bold;
}
.card-container .form-control {
  height: 3rem;
}

.card-container .btn {
  background-color: #1a2753;
  margin-top: 20px;
  width: 100%;
  height: 3rem;
  font-size: 15px;
}
.card-container .btn:hover {
  background-color: #1a2753;
}
.talkings {
  padding: 34px 70px;
  background: rgba(248, 249, 250);
  padding-bottom: 120px;
}
.talkings .label {
  background: white;
  width: 700px;
  padding: 50px 24px;
  border-left: 4px solid #1a2753;
}

.talkings .label p {
  font-size: 25px;
  line-height: 1.2;
  font-family: "Poppins";
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.talkings .avatar {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin-right: 40px;
}

.talkings .talking {
  margin-right: 25px;
  margin-left: 25px;
}

.talkings .bytalking {
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  align-items: center;
}

.talkings h6 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  color: #131c21;
  font-style: normal;
}

.talkings p {
  font-size: 14px;
  line-height: 1.2;

  font-style: normal;
}

.talkings .talking-box {
  display: flex;
  justify-content: space-around;
}

.talkings .one {
  display: none;
}

.blog-section-container-mobile {
  display: none;
}
.homepage-banner{
  width: 100%;
  height: 700px;
}

@media only screen and (max-width: 1750px) {
  .video-container .deli-car {
    display: none;
  }
}

@media only screen and (max-width: 1450px) {
  .services .image-cards,
  .blog-group {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .services .image-cards div,
  .blog-section {
    margin-bottom: 20px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }

  .video-container div:first-child {
    width: 800px;
    margin-bottom: 40px;
  }

  .video-container div:first-child iframe {
    width: 80%;
    height: 420px;
    border: none;
    margin-left: 10%;
  }

  .video-container div:nth-child(2) p {
    width: 100%;
  }

  .inquiry-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 0 !important;

    align-items: center;
    justify-content: center;
  }

  .inquiry-container div {
    width: 80%;
  }

  .inquiry-container div:first-child .form-card {
    width: 80% !important;
    padding: 44px 20px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info {
    margin-left: 44px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info h1 {
    width: 90%;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info p {
    width: 90%;
  }

  .inquiry-container .social-list {
    margin-left: 30px;
  }

  .numbers-container {
    padding: 24px 100px;
  }

  .talkings .two {
    display: none;
  }

  .talkings .one {
    display: block;
  }

  .why-choose-us-content p {
    font-size: 16px;
  }
  .homepage-banner{
  width: 100%;
  height: 600px;
}
}

@media only screen and (max-width: 1250px) {
  .side-banner {
    flex-direction: row;
  }
  .side-banner .tb-container {
    display: flex;
  }
  .side-banner div {
    margin-bottom: 18px;
  }
  .homepage-banner{
  width: 100%;
  height: 500px;
}
  /* .video-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
  }

  .video-container div:first-child {
    width: 400px;
    margin-bottom: 40px;
  } */

  .inquiry-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 0 !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .inquiry-container div {
    width: 90%;
  }

  .inquiry-container div:first-child .form-card {
    width: 80% !important;
    padding: 44px 10px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info {
    margin-left: 25px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info h1 {
    width: 90%;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info p {
    width: 90%;
  }

  .inquiry-container .social-list {
    margin-left: 10px;
  }
  .card-container {
    margin-left: 10px;
  }
  .check-icon {
    margin-left: 50px;
  }
 
}

@media only screen and (min-width: 950px) {

.about-img img {
  width:100%;
  height:600px;
  object-fit: cover;
  object-position: center;
  position: relative;
  top:-200px;
}
}

@media only screen and (max-width: 950px) {

.about-img img {
  width:100%;
 
}
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
  }

  .video-container div:first-child {
    width: 600px;
    margin-bottom: 40px;
  }

  .video-container div:first-child iframe {
    width: 60%;
    height: 400px;
    border: none;
    margin-left: 10%;
  }

  .video-container div:nth-child(2) {
    margin-left: 5px;
  }
  .video-container div:nth-child(2) p {
    width: 100%;
  }

  .inquiry-container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-left: 0 !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .inquiry-container div {
    width: 80%;
  }

  .inquiry-container div:first-child .form-card {
    width: 70% !important;
    padding: 44px 10px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info {
    margin-left: 35px;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info h1 {
    width: 90%;
  }

  .inquiry-container div:nth-child(2) .inquiry-right-info p {
    width: 90%;
  }

  .inquiry-container .social-list {
    margin-left: 10px;
  }
  .review-container {
    width: 400px;
  }
  .card-container {
    width: 300px;
  }
  .numbers-container {
    flex-direction: row;
    padding: 34px 154px;
  }

  .numbers-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .talkings .two {
    display: none;
  }

  .talkings .one {
    display: block;
  }
}

@media only screen and (max-width: 850px) {
  .inquiry-container div {
    width: 100%;
  }
  .about p {
    padding: 0%;
  }
  .about-title {
    padding: 0%;
  }
  .reviews {
    flex-direction: column;
  }
  .review-container {
    width: 600px;
  }
  .card-container {
    margin-top: 50px;
    width: 400px;
  }
}

@media only screen and (max-width: 650px) {
  .side-banner {
    flex-direction: column;
  }
  .call-support,
  .location {
    justify-content: start;
  }
  .services .subtitle {
    font-size: 15px;
  }
  .services .title {
    font-size: 30px;
  }
  .about-title span {
    font-size: 15px;
  }
  .about-title h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .review-container {
    width: 300px;
  }
  .review-button-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .card-container {
    margin-top: 50px;
    width: 270px;
  }
  .talkings .label {
    background: white;
    width: 200px;
    padding: 50px 24px;
    border-left: 4px solid red;
  }
  .numbers-container {
    flex-direction: column;
    padding: 34px 154px;
  }

  
  .check-icon{
    font-size: 25px;
    display: block;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 950px) {
  .talkings {
    background: rgba(248, 249, 250);
    padding-bottom: 180px;
    margin: 0px;
    padding: 0px 35px;
  }

  .blog-section-container {
   
    padding: 20px 12px;
   
  }

  .talkings .label {
    background: white;
    width: 320px;
    padding: 50px 24px;
    border-left: 4px solid red;
  }

  .talkings .label p {
    font-size: 19px;
    line-height: 1.2;
    font-family: "Poppins";
    font-weight: 700;
    color: #131c21;
    font-style: normal;
  }

  .talkings .talking {
    margin-right: 10px;
    margin-left: 10px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-container div:first-child {
    width: 30%;
    margin-bottom: 40px;
    margin-left: 150px;
  }

  .video-container div:first-child iframe {
    width: 50%;
    margin-left: 14%;
    height: 240px;

    border: none;
  }

  .video-container div:nth-child(2) {
    padding-left: 0px;
  }

  .video-container div:nth-child(2) p {
    width: 100%;
  }

  .inquiry-submit-btn {
    padding: 20px 35px !important;
    width: 80%;
    border: 1px solid #1a2753 !important;
    background: #1a2753 !important;
    margin-right: 10%;
  }
  .check-icon{
      margin-left: 50px;
    }
    .why-choose-us-content h5{
      font-size: 20px;
    }
}
@media screen and (max-width: 825px) and (min-width: 414px) {
  .side-banner{
  padding: 30px 50px;
}
.call-support,
.location {
  margin-right: 0px;
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.services {
  padding-top: 40px;
}
}

@media screen and (max-width: 414px) {

 
  .side-banner{
  padding: 30px 50px 0px 50px;
}
.call-support,
.location {
  width: 320px;
  margin-right: 0px;
  min-height: 70px;
}
.location{
  gap: 30px;
}
.location-icon{
  margin-left: 10px;
}
.about p {
  font-size: 18px;
}
.about h2, .services h2{
  font-size: 1.8rem;
}

.services p{
  font-size: 15px;
  padding: 0px 20px;
}
.blog-section {
  padding: 0px 14px;
}
.services{
  padding: 20px 12px;
}
.services .text-container{
  margin-top: 0px;
}
.services .text-container p:first-child {
  margin-right: 0px;
}
.services .text-container{
  flex-direction: column;
}
.services .text-container p{
  width: 100%;
}
.homepage-banner{
  width: 100%;
  height: 400px;
}
.about {
  padding: 50px 50px 20px 50px;
}
}