.footer-img {
  width: 80%;
}
.contact-info {
  .contact-ways {
    width: 130px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 820px) {
  .contact-info {
    span {
      font-size: 0.72rem;
    }
  }
}

@media screen and (max-width: 820px) and (min-width: 415px) {
  .footer-img {
    width: 200px;
    margin: 20px 0px;
  }
  .contact-info {
    display: flex;
    justify-content: center;

    width: 100%;
    padding: 20px 0px;
    div,
    h4,
    ul {
      padding-left: 20%;
    }
  }
}

@media screen and (max-width: 415px) {
  .footer-img {
    width: 160px;
    margin: 10px 0px;
  }

  .footer-link {
    font-size: 16px;
  }

  .contact-info {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0px;

    div,
    h4,
    ul {
      padding-left: 10%;
      span {
        font-size: 0.75rem;
      }
    }
    .contact-ways {
      width: 125px;
    }
  }
}
