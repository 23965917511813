.header > h5 {
  color: #1c365c;
  font-weight: bolder;
  padding: 0;
}
.header > span {
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
  padding: 0;
}

.track_input > div > span {
  font-size: 10px;
  color: #1c365c;
  font-weight: 600;
  padding: 0;
}
.track_input > div > input {
  height: 30px;
  padding: 0;
}
.waybill_info {
  border: 1px solid black;
  padding: 10px;
  display: flex;
}

.waybill_info > div > span {
  padding-bottom: 10px;
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
}
.waybill_info > div > h6 {
  color: #1c365c;
  margin-top: 10px;
}
.divider {
  border-bottom: 1px solid gray;
  padding: 10px 0px;
  margin-bottom: 35px;
}
.info_details {
  border: 1px solid black;
  margin-top: 20px;
}
.info_details > .date_box {
  border-bottom: 1px solid black;
  display: flex;
  padding: 10px;
}
.info_details > .date_box > .icon {
  font-size: 30px;
  margin-right: 20px;
}
.info_details > .date_box > div > span {
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
}
.info_details_left {
  border-right: 1px solid black;
  min-height: 400px;
  padding-top: 30px;
}
.info_details_right {
  padding-top: 30px;
}
.info_row_header > div > span {
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
}
.info_row_content > div > span {
  font-size: 14px;
}
@media screen and (max-width: 825px) and (min-width: 414px) {
  .info_row_header > div > span,
  .info_row_content > div > span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 414px) {
  .track_input > button {
    font-size: 12px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info_details_left {
    border-right: 0px;
    border-bottom: 1px solid black;
  }
}

/* .nav-tabs.vertical {

  flex-direction: column;
  border: 1px solid #dee2e6;
}

.nav-tabs.vertical .nav-link  {
  
  border: 1px solid transparent;
  border-radius: 0px;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  background: none;

}



.nav-pills .nav-link.active
{
  background: none !important;
}

.nav-tabs.vertical .active {
color: #495057;
background-color: #fff;
border-color: #dee2e6 #fff;
}

.icon {
  background:red;
  text-align: center;
  line-height: 50px;
  width:50px;
  height:50px;
  border-radius: 100%;
  float:left;
}

.icon img {
  width:30px;
  height:30px;
  
}






@keyframes animate {
  0%,
  100% {
    background-image: url(../assets/imgs/tracking_banner.jpg);
  }
  25% {
    background-image: url(../assets/imgs/background_img_new.jpg);
  }
  50% {
    background-image: url(../assets/imgs/info.jpg);
  }
  75% {
    background-image: url(../assets/imgs/background_img.jpg);
  }
}
.backdrop {
  width: 100%;
  height: 45vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 8vh;
}

.backdrop h2 {
  color: white;
  text-shadow: 1px 1px 1px black;
}
.tracking_container {
  padding-bottom: 40px;
  padding: 1rem;
  min-height: 300px;
  position: relative;
}

.tracking_title {
  font-weight: bold;
}

.tracking_search_row {
  background-color: #ffffff;
  display: flex;
  min-height: 80px;
  padding: 1rem;
  position: absolute;
  top: 40%;
  left: 18%;
  right: 18%;
}

.tracking_search_label {
  margin: 0;
  padding: 0;
  text-align: right;
}
.adjust_size_Wrapper {
  margin-top: 10%;
}
.tracking_search_btn {
  width: 150px;
}

.tabs_container {
  margin-top: 3.5rem;
  background-color: #ffffff;
}

.main_info {
  margin: 1rem;
}

.main_info_container {
  background-color: #f7f6fc;
  padding: 2rem;
  display: flex;
}

.main_info_title {
  text-align: right;
}

.nav-tabs.vertical {
  flex-direction: column;
  border: 1px solid #dee2e6;
}

.nav-tabs.vertical .nav-link {
  border: 1px solid transparent;
  border-radius: 0px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: none;
}

.nav-pills .nav-link.active {
  background: none !important;
}

.nav-tabs.vertical .active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #fff;
}

.icon {
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  float: left;
  font-size: 28px;
}

.icon .svg {
  width: 45px;
  height: 45px;
}

.icon img {
  width: 30px;
  height: 30px;
}

.left-info {
  position: relative;
  color: black !important;
  right: 80px;
  top: 50px;
}

.right-info {
  color: black !important;
  position: absolute;
  left: 120px;
  width: 500px;
}

.nav-pills.flex-column .nav-link {
  position: relative;
  width: fit-content;
  margin: 10px 0rem;
  border-radius: 50%;
}

.nav-pills.flex-column .nav-link:not(:last-child)::before {
  display: block;
  position: absolute;
  content: "";
  border: 1px solid #495057;
  top: 100%;
  height: 8rem;
  left: 50%;
}

.arrow-svg {
  width: "32%";
  height: 40px;
}
.collapse_container {
  display: none;
}

@media screen and (max-width: 825px) and (min-width: 414px) {
  .content,
  .backdrop {
    height: 35vh;
  }
  .tracking_search_row{
    top: 32%;
  }
}
@media only screen and (max-width: 770px) {
  .tracking_search_col {
    padding-top: 8px;
  }
  .tracking_search_label {
    text-align: left;
  }
}
@media screen and (max-width: 414px) {
  .content,
  .backdrop {
    height: 30vh;
  }

  .main_info_title {
    text-align: right;
  }
  .backdrop {
    padding-top: 4vh;
  }
  .tracking_container{

    margin-top: 250px;

  }
  .tracking_search_row {
    background-color: #ffffff;
    display: flex;
    min-height: 80px;
    padding: 1rem;
    position: absolute;
    top: 35%;
    left: 18%;
    right: 18%;
  }
  .tracking_search_btn {
    width: 120px;
  }
  .tabs_container {
    display: none;
  }
  .collapse_container {

    margin-top: 0vh;

    padding-top: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .collapse_container button,
  .collapse_container button:hover,.collapse_container button:focus {
    background-color: #2b5188;
    color: white;
    width: 340px;
    box-shadow: none;
  }
  .main_info_title {
    font-weight: bold;
   
  }

  .main_info_title  p {
    text-align: left;
  }


  .main_info_container {
    flex-direction: column;
}
} */

/* .way-info {
  margin-top: 30px;
  width: 70%;
  margin-left: 15%;
}

.title-box {
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 0.5px solid rgb(0, 0, 0, 0.3);
}
.title-box .info-box {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.nav-tabs.vertical {
  flex-direction: column;
  border: 1px solid #dee2e6;
}

.nav-tabs.vertical .nav-link {
  border: 1px solid transparent;
  border-radius: 0px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: none;
}

.nav-pills .nav-link.active {
  background: none !important;
}

.nav-tabs.vertical .active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #fff;
}

.icon {
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  float: left;
  font-size: 28px;
}

.icon .svg {
  width: 45px;
  height: 45px;
}

.icon img {
  width: 30px;
  height: 30px;
}

.left-info {
  position: relative;
  color: black !important;
  right: 80px;
  top: 50px;
}

.right-info {
  color: black !important;
  position: absolute;
  left: 120px;
  width: 500px;
}

.nav-pills.flex-column .nav-link {
  position: relative;
  width: fit-content;
  margin: 10px 0rem;
  border-radius: 50%;
}

.nav-pills.flex-column .nav-link:not(:last-child)::before {
  display: block;
  position: absolute;
  content: "";
  border: 1px solid #495057;
  top: 100%;
  height: 8rem;
  left: 50%;
}

.arrow-svg {
  width: "32%";
  height: 40px;
}

@media only screen and (max-width: 800px) {
  .way-info {
    margin-top: 30px;
    width: 90%;
  }

  .title-box {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 0.5px solid rgb(0, 0, 0, 0.3);
  }
  .title-box .info-box {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .right-info {
    width: 250px;
  }

  .arrow-svg {
    width: 100px;
    height: 40px;
  }
} */
