.content {
    width: 100%;
    height: 45vh;
    /* background: url("../assets/imgs/info.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   animation: animate 20s infinite;
}
@keyframes animate {
  0%,
  100% {
    background-image: url(../assets/imgs/info.jpg);
  }
  25% {
    background-image: url(../assets/imgs/tracking_banner.jpg);
  }
  50% {
    background-image: url(../assets/imgs/background_img_new.jpg);
  }
  75% {
    background-image: url(../assets/imgs/background_img.jpg);
  }
}
  
  .backdrop {
    width: 100%;
    height: 45vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
  }

  .banner_image{
    background: url("../assets/imgs/background_img.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.office_details{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}
.office_details ul li{
    list-style: inside;
}
.office_details ul{
    padding: 20px 0px;
}
.office_details h4, .office_details h5{
    font-weight: bold;
}
.office_details p{
    text-align: justify;
    padding: 20px 0px;
}

.image{
    width: 100%;
    height: 100%;
}


.second_banner_image{
    background: url("../assets/imgs/background_img.png");
    background-repeat: no-repeat;
    background-size: cover;
    height:400px;
    background-position: center;
    display: flex;
    justify-content: end;
}

.why_choose_us_section {
    background-color: #f0f5f5;
    
    
  }


.bannerDrop {
    width:40%;
    min-width:400px;
    height: 100%;
    background:#205a7b;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(16% 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(16% 0, 100% 0, 100% 100%, 0% 100%);
    
}

.bannerDrop h3 {
    padding-bottom: 16px;
}

.bannerDropInfo {
    display: flex;
    color: white
}

.bannerDropInfo img {
    width:20px;
    height:20px;
}

@media screen and (max-width: 825px) and (min-width: 390px) {
  .content ,.backdrop{
    height: 35vh;
 }
}
@media screen and (max-width: 390px) {
  .content ,.backdrop{
    height: 30vh;
 }

}
