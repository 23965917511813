.link-style{
    text-decoration: none;
    color: #333;
    font-size: 18px;
}
.active{
    color: #1a2753;
    font-weight: bold;
}

.navbar-icon {
    width:250px;
}

@media only screen and (max-width: 990px) {
    .navbar-icon {
        width:180px;
    }
.navbar-collapse{
    background-color: #1a2753;
    color: white;
    width: 90%;
    position: absolute;
    top: 100%;
    right: 0%;
    left: 5%;
    padding: 0px 30px;
    z-index: 100;
}
.link-style{
    color: white;
}
#basic-nav-dropdown{
    color: white;
}

}