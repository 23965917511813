.content {
    width: 100%;
    height: 45vh;
    /* background: url("../assets/imgs/background_img.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   animation: animate 20s infinite;
}
.phones{
        
    margin-left: 30px;
}
@keyframes animate {
  0%,
  100% {
    background-image: url(../assets/imgs/background_img.jpg);
  }
  25% {
    background-image: url(../assets/imgs/tracking_banner.jpg);
  }
  50% {
    background-image: url(../assets/imgs/info.jpg);
  }
  75% {
    background-image: url(../assets/imgs/background_img_new.jpg);
  }
}
  
  .backdrop {
    width: 100%;
    height: 45vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
  }
  .banner_image{
      background: url("../assets/imgs/background_img.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
  .office_details{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .office_details h2{
      color: rgb(110, 109, 109);
      font-weight: 500;
      padding: 20px 0px;
  }
  .office_details p, .contact_details span{
      color: rgb(110, 109, 109);
      padding: 10px 0px;
  }
  .image{
      width: 100%;
      height: 100%;
  }
  .contact_details{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  .icon_design{
      color: #6CE1EE;
      font-size: 25px;
      margin-right: 10px;
  }
  .phones{
      display: flex;
  }
  .phones p{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
  }
  .phones p span{
      padding: 0%;
  }
  .email{
      display: flex;
      flex-direction: column;
  }
  .email span{
      padding: 0%;
  }
  .icon_circle_design{
     border: 0.5px solid  rgb(190, 188, 188);
     color: rgb(125, 124, 124);
     width: 30px;
     padding: 6px;
     height: 30px;
     border-radius: 50%;
     margin-right: 10px;
  }
  @media only screen and (max-width: 1250px) {
      .office_details{
          flex-direction: column;
      }
      .contact_details span{
          font-size: 13px;
      }
  }
  @media only screen and (max-width: 1250px) {
      .contact_details{
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
  }
  }

  @media only screen and (max-width: 414px) {
    .phones{
        
        margin-left: 24px;
    }
}

